<template>
  <section id="finance-education" class="mt-24 lg:mt-28">
    <div class="container flex justify-center p-15 relative">
      <img
        class="otto-logo absolute"
        width="86"
        height="90"
        src="../assets/svgs/otto-avatar-logo.svg"
        alt="avatar"
      />
      <h3 class="text-white font-normal text-center">
        Fornecer educação financeira é uma vantagem quando falamos de atração,
        retenção e também de engajamento do colaborador.
      </h3>
    </div>
  </section>
</template>

<script>
import { Vue } from "vue-class-component";

export default class FinanceEducation extends Vue {}
</script>

<style scoped>
#finance-education {
  background-color: #2769f7;
}

.otto-logo {
  width: 64px;
  top: -30px;
}
#finance-education .container h3 {
  width: 600px;
}
</style>
